const themeGen = (theme) => ({
  // eslint-disable-line
  iconButtonMode: 'inverted',
  plusIcon: 'plus-circle',
  minusIcon: 'minus-circle',
  roundIcon: true,
  iconSize: 'xl',
  iconType: 'light',
  showProductDetails: true,
  quantityButtonColor: 'tertiary-inverted',
  quantityButtonHoverColor: 'solid',
  showPerPiecePriceTitle: true,
  productNameWidth: 'ic-col-5',
  productNameWidthExceptCartPage: 'ic-col-6',
  totalColumnWidth: 'ic-col-1',
  totalColumnWidthExceptCartPage: 'ic-col-2',
  conf: {
    showPerKronaInCartTable: false,
  },
  countButtonColor: 'pale',
});

export default themeGen;
