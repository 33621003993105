const themeGen = theme => { // eslint-disable-line
  const res = {
    showUpsellSection: true,
    // If you want to add a new column here you also need to add it to the list of valid columns in productsSvc
    columns: [
      {
        columnId: 'productImage',
        size: 1,
        classes: '',
      },
      {
        columnId: 'productCode',
        size: 1,
        classes: 'productCodeTableData',
      },
      {
        columnId: 'productCorrespondsTo',
        size: 1,
        classes: 'productCorrespondsToTableData',
      },
      {
        columnId: 'productManufacturer',
        size: 1,
        classes: 'productManufacturerTableData',
      },
      {
        columnId: 'productName',
        size: 1,
        classes: 'lg:w-1/4',
      },
      {
        columnId: 'stock',
        size: 1,
        classes: 'stockTableData',
      },
      {
        columnId: 'price',
        size: 1,
        classes: 'md:w-1/5 lg:w-auto md:px-1 priceTableData text-center',
      },
      {
        columnId: 'addToCartAndSubscription',
        size: 1,
        classes: 'text-right',
      },
    ],
    useOddRow: false,
    productCodeColor: theme.col.pale.alt,
    conf: {
      showOneKronaSticker: true,
    },
  };
  return res;
};

export default themeGen;
