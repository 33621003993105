// eslint-disable-next-line no-unused-vars
const themeGen = (theme) => {
  const res = {
    columns: ['headerTitle', 'amountSaved', 'arrow'],
    sizes: { headerTitle: 66.5, amountSaved: 30, arrow: 4.5 },
  };
  return res;
};

export default themeGen;
