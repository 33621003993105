const themeGen = theme => { // eslint-disable-line
  const res = {
    excludeSameProductFromShelf: false,
    showPrinterModelRecommendation: false,
    showVaccumModelRecommendation: false,
    sections: ['budget', 'recommended', 'original', 'optimum'],
    conf: {
      shelfPageProductsOrdering: [
        'oneBuyProducts',
        'budget',
        'recommended',
        'original',
        'optimum',
        'upselling',
        'freeCategories',
      ],
      shelfPageCheckProductSection: ['recommended', 'optimum', 'budget', 'original'],
    },
    backgroundGutter: true,
    backgroundColor: 'white',
    hideShelfTitleOnMobile: false,
  };
  return res;
};

export default themeGen;
