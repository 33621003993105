const themeGen = theme => ({ // eslint-disable-line
  roundIcon: true,
  iconColor: 'tertiary',
  iconButtonColor: 'pale',
  noBorderOnCountField: false,
  fixedCounterWidth: '50%',
  fixedCounterHeight: '32px',
  panelTopMargin: '8px',
  panelBottomMargin: '6px',
  panelWidth: '10%',
  panelWidthForPopup: '40%',
  panelWidthIpad: '17%',
  panelWidthIpadForPopup: '50%',
  panelLeftMarginMobile: '0px',
  panelLeftPaddingMobile: '10px',
  fixedIconButtonPadding: '5px',
  fontSizeCounterField: '20px',
  panelLeftPadding: '20px',
  fixedIconButtonHeight: '32px',
  fixedIconButtonWidth: '32px',
  hideInMobile: true,
  showFixedWidthQuantityCounter: true,
  readOnly: false,
});

export default themeGen;
